import { useIsLoggedIn } from '../global-storage/auth'
import { useSignInOverlay } from '../global-storage/overlays'
import { useRouter } from 'next/router'
import localeData from 'json/locale.json'
import basepath, { navigate } from './basepath'
import { useTranslation } from 'next-i18next'

export default function MobileNav({cartCount,locale }) {
  const { openOverlay, closeOverlay } = useSignInOverlay(({openOverlay, closeOverlay}) => ({openOverlay, closeOverlay}))
  const {isLoggedIn} = useIsLoggedIn(({isLoggedIn}) => ({isLoggedIn}))
  const router = useRouter()
  const { t } = useTranslation(['header','footer'])

  return (
      <div className="nav-5 pos-fix bg-white fullwidth main-nav br-t-gray-1 a-767 sr t-05-ease" style={{zIndex:'100'}}>
        <div className="flex-box ai-c slide-disable col3 fw jc-c">
          <div
            onClick={() => {
              if ((process.env.NEXT_PUBLIC_ENV == 'prod' || process.env.NEXT_PUBLIC_ENV == 'uat') && Gleap?.getInstance()) {
                Gleap.open()
              } else {
                window.open(
                  `https://api.whatsapp.com/send?phone=60179722669&text=%5BB2C%5D Please help me regarding: ${window.location.href}`,
                  '_blank'
                )
              }
            }}
            className="ta-c f-g1-s0 flex-box fd-c ai-c jc-sb gap-05"
          >
            <div className="pos-rel flex-box ai-c">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                style={{ enableBackground: 'new 0 0 50 50' }}
                xmlSpace="preserve"
                width={25}
                height={25}
              >
                <style
                  type="text/css"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\n            .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;}\n          '
                  }}
                />
                <g>
                  <path
                    id="Rectangle_103_"
                    className="st0"
                    d="M19.83,31.77l-6.29,6.29c-0.63,0.63-1.71,0.18-1.71-0.71v-5.59h-8c-1.1,0-2-0.9-2-2v-26 c0-1.1,0.9-2,2-2h30c1.1,0,2,0.9,2,2v26c0,1.1-0.9,2-2,2H19.83z"
                  />
                  <path
                    id="Path_143_"
                    className="st0"
                    d="M23.83,35.77v4c0,1.12,0.9,2,2,2h10l5.29,5.7c0.63,0.64,1.71,0.19,1.71-0.72v-4.98h3 c1.1,0,2-0.88,2-2V22.8c0-1.12-0.9-2.03-2-2.03h-6"
                  />
                  <path className="st0" d="M18.83,19.77c0,0,0-2,1-3c2-2,3-3,3-5s-1-4-4-4s-4,2-4,4" />
                  <line className="st0" x1="18.83" y1="23.77" x2="18.83" y2="24.77" />
                </g>
              </svg>
            </div>
            <span>{t('needHelp')}</span>
          </div>

          <div
            className="ta-c f-g0-s0 flex-box fd-c ai-c jc-sb gap-05"
            onClick={() => {
              if (isLoggedIn) {
                closeOverlay()
                window.location.href = basepath(`${locale==='en'?'':"/"+locale}/profile`)
              } else {
                openOverlay()
              }
            }}
          >
            <div className="pos-rel flex-box ai-c">
              <svg
                className="header-login"
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                viewBox="0 0 25 25"
              >
                <g transform="translate(-1121 -191)">
                  <rect className="class-a" width={25} height={25} transform="translate(1121 191)" />
                  <g transform="translate(1123 193)">
                    <path
                      className="class-b"
                      d="M10.988,13.129A5.363,5.363,0,0,1,5.852,7.564,5.363,5.363,0,0,1,10.988,2a5.363,5.363,0,0,1,5.136,5.564A5.363,5.363,0,0,1,10.988,13.129Zm0,0q7.7,0,8.988,6.848H2Q3.284,13.129,10.988,13.129Z"
                      transform="translate(0 0)"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <span>{isLoggedIn ? 'Profile' : 'Sign In'}</span>
          </div>

          <a href={`${localeData[router.locale]}/cart`} className="ta-c f-g1-s0 flex-box fd-c ai-c jc-sb gap-05">
            <div className="pos-rel flex-box ai-c">
              <div className="cart-count"> {cartCount} </div>
              <svg
                className="header-cart-icon"
                xmlns="http://www.w3.org/2000/svg"
                width={25}
                height={25}
                viewBox="0 0 25 25"
              >
                <g transform="translate(0)">
                  <path className="class-a" d="M0,0H25V25H0Z" transform="translate(0)" />
                  <path
                    className="class-b"
                    d="M11.5,19h6.279a.5.5,0,0,1,0,1H11.5a3.5,3.5,0,0,1-3.487-3.2,3.5,3.5,0,0,1-2.306-2.912L4.694,5.318A1.5,1.5,0,0,0,3.205,4H2.5a.5.5,0,0,1,0-1h.705A2.5,2.5,0,0,1,5.687,5.2L5.907,7H21.5a.5.5,0,0,1,.488.608L20.51,14.259A3.5,3.5,0,0,1,17.094,17H9.185l-.136,0A2.5,2.5,0,0,0,11.5,19ZM6.059,8,6.7,13.776A2.5,2.5,0,0,0,9.185,16h7.908a2.5,2.5,0,0,0,2.44-1.958L20.877,8Z"
                    transform="translate(0)"
                  />
                  <path
                    className="class-b"
                    d="M18,23a2,2,0,1,1,2-2A2,2,0,0,1,18,23Zm0-1a1,1,0,1,0-1-1A1,1,0,0,0,18,22Z"
                    transform="translate(0)"
                  />
                  <path
                    className="class-b"
                    d="M11,23a2,2,0,1,1,2-2A2,2,0,0,1,11,23Zm0-1a1,1,0,1,0-1-1A1,1,0,0,0,11,22Z"
                    transform="translate(0)"
                  />
                </g>
              </svg>
            </div>
            <span>Cart</span>
          </a>
        </div>
      </div>
    )
}
