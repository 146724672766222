import { usePromiseTracker } from 'react-promise-tracker'
import TopBarProgress from 'react-topbar-progress-indicator'
import Loader from '#components/Loader'

TopBarProgress.config({
  barColors: {
    0: '#03a9f4',
    '1.0': '#03a9f4'
  },
  barThickness: 5
})

function TopBar() {
  const { promiseInProgress } = usePromiseTracker()
  return (
    <>
      {promiseInProgress && (
        <>
          <TopBarProgress /> <Loader />
        </>
      )}
    </>
  )
}

export default TopBar
