import localeMapper from 'json/localeMapper.json';

const replaceCategoryInUrl = (href, countryLocale, category, currentLocale) => {
  if (localeMapper[countryLocale][category]) {
    const replaceWord = localeMapper[countryLocale][category];
    return href.replace(
      localeMapper[Object.keys(localeMapper).find(key => localeMapper[key].locale === currentLocale)][category],
      replaceWord
    );
  }
  return href;
};

const hreflangGenerator = ({ countriesLocale, currentLocale, pageProps, fullUrl, slugArray }) => {
  if (!fullUrl) {
    console.error('No full url is passed')
    return null;
  }

  const { categoryUrl, categoryList } = pageProps;

  return countriesLocale.map(countryLocale => {
    let href = fullUrl;

    if (currentLocale !== 'en') {
      href = href.replace(`/${currentLocale}`, localeMapper[countryLocale].countrySlug);
    } else {
      href = href.replace('/', `${localeMapper[countryLocale].countrySlug}/`);
    }

    if (slugArray) {
      slugArray.forEach(category => {
        href = replaceCategoryInUrl(href, countryLocale, category, currentLocale);
      });
    }

    if (categoryUrl) {
      href = href.replace(categoryUrl, categoryList[localeMapper[countryLocale].language].url_slug);
    }

    return {
      hreflang: countryLocale,
      href
    };
  });
};

module.exports = { hreflangGenerator };
