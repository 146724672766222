import basepath from './basepath'
import { Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { loaderProp } from '#root/utils/image-utils'
import { usePackageCategories, useProductCategories } from '../global-storage/categories' // AF


export default function Footer({availableCountries, locale}) {
  const { t } = useTranslation('footer')

  let curYear = new Date().getFullYear()

  {/* AF - ADDED */}
  const {data: productCategories } = useProductCategories() //AF
  const {data: packageCategories} = usePackageCategories() //AF

  const pharmacySlugs={
    id:'apotek',
    en:'pharmacy'
  }
  const pharmacySlugUrl= pharmacySlugs[locale] || pharmacySlugs['en']
  {/* AF END - ADDED */}

  return (
    <Fragment>
      <footer className="section bg-blue-1 slide-disable t-05-ease sr pb-2">
        <div className="wrap-1 pad">
          <div className="footer flex-box desc gap-1 jc-sb cen centralized fw">
          <div className="fb-30" style={{ filter: 'brightness(100)' }}>
              {' '}
              <Image
                placeholder='blur'
                blurDataURL={basepath("/images/doc-logo.png")}
                src={basepath("/images/doc-logo.png")}
                alt={"logo"}
                style={{ width: '6em', filter: 'brightness(100)' }}
                width={84}
                height={38}
                loader={loaderProp}
              />  
              <p className="mt-1">
                  {t('footerDescription')}
              </p>
            </div>
            <div>
              <h2>{t('onlinePharmacy')}</h2>
              <p>
              {productCategories.map((category, index) => (
                <a href={`${locale==='en'?'':"/"+locale}/${pharmacySlugUrl}/${category?.language[locale.split('-')[0]]?.url_slug||category?.language['en']?.url_slug}`}>{category?.language[locale.split('-')[0]]?.name||category?.language['en']?.name}</a>
              ))}
              </p>
            </div>
            <div>
              <h2>{t('healthServices')}</h2>
              <p>
              {packageCategories.map((category, index) => (
                <a href={basepath(`${locale==='en'?'':"/"+locale}/health-services/categories/${category.url_slug}`)}>{category.name}</a> // NO INDONESIAN TRANSLATION
              ))}
              </p>
            </div>
            <div>
              <h2>DoctorOnCall</h2>
              <p>
                <a href={basepath(`${locale==='en'?'':"/"+locale}/about-us`)}>{t('aboutUs')}</a>
                {/* <a href={basepath(`${locale==='en'?'':"/"+locale}/contact-us`)}>{t('contactUs')}</a> */}
                <a href={basepath(`${locale==='en'?'':"/"+locale}/corporate-partners`)}>{t('corporatePartners')}</a>
                <a href={basepath(`${locale==='en'?'':"/"+locale}/dispensation-policy`)}>{t('dispensation')}</a>
                <a href={basepath(`${locale==='en'?'':"/"+locale}/privacy-policy`)}>{t('privacyPolicy')}</a>
                <a href={basepath(`${locale==='en'?'':"/"+locale}/term-of-use`)}>{t('TermUse')}</a>
                {/* <a href="https://www.doctoroncall.com.my/privacyPolicy">{t('privacy')}</a>
                <a href="https://www.doctoroncall.com.my/help">{t('faq')}</a>
                <a href="https://www.doctoroncall.com.my/termsOfService">{t('termsOfUse')}</a>
                <a href="https://www.doctoroncall.com.my/doctor#/signin">{t('doctorsLogin')}</a>
                <a href="https://www.doctoroncall.com.my/media">{t('media')}</a> */}
                
                {/* <a href="https://www.doctoroncall.com.my/careers">{t('careers')}</a> */}
                
              </p>
            </div>
            <div>
              <h2>{t('docNetwork')}</h2>
              <p>
                <a href="https://www.doctoroncall.com/marketplace">{t('docB2B')} </a>
                <a href="https://www.doctoroncall.com.my/healthcare-ai">{t('docAI')} </a>
              </p>
                <h2>Countries</h2>
                  <p className="footer-countries">
                  {availableCountries.map((country,i) => (
                    <a className={country.short.replace(/[^a-zA-Z ]/g, "").toLowerCase()}
                    key={country.short}
                    href={`/${country.url}`}
                    >
                      {` ${country.name} `}
                    </a>
                  ))}
                  </p>
                  {/*
              <div className="r-500">
                <h2>{t('followUs')}</h2>
                <div className="socmed flex-box col5">
                  {' '}
                  <a href="https://www.facebook.com/doctoroncallMY" target="_blank">
                    <img src={basepath('/images/socmed-fb.svg')} />
                  </a>{' '}
                  <a href="https://www.instagram.com/doctoroncallmy/" target="_blank">
                    <img src={basepath('/images/socmed-ig.svg')} />
                  </a>{' '}
                  <a href="https://twitter.com/doctoroncallmy" target="_blank">
                    <img src={basepath('/images/socmed-tw.svg')} />
                  </a>{' '}
                  <a href="https://www.youtube.com/c/DoctorOnCallMalaysia" target="_blank">
                    <img src={basepath('/images/socmed-yt.svg')} />
                  </a>{' '}
                  <a href="https://www.linkedin.com/company/doctoroncall.com.my" target="_blank">
                    <img src={basepath('/images/socmed-in.svg')} />
                  </a>{' '}
                </div>
              </div>
                  */}
            </div>
            {/*
            <div className="dn a-500 mt-1">
              <h2 className="ta-c">{t('followUs')}</h2>
              <div className="socmed flex-box col5 centralized" style={{ maxWidth: '20em' }}>
                {' '}
                <a href="https://www.facebook.com/doctoroncallMY" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-fb.svg'}
                    src={'/images/socmed-fb.svg'}
                    alt={"fb-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://www.instagram.com/doctoroncallmy/" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-ig.svg'}
                    src={'/images/socmed-ig.svg'}
                    alt={"ig-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://twitter.com/doctoroncallmy" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-tw.svg'}
                    src={'/images/socmed-tw.svg'}
                    alt={"tw-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://www.youtube.com/c/DoctorOnCallMalaysia" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-yt.svg'}
                    src={'/images/socmed-yt.svg'}
                    alt={"yt-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://www.linkedin.com/company/doctoroncall.com.my" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-in.svg'}
                    src={'/images/socmed-in.svg'}
                    alt={"in-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
              </div>
            </div>
            */}
            <div className="fb-100 ta-c">
                <h2 className='mb-0'>{t('followUs')}</h2>
                <div className="socmed flex-box col5 centralized">
                {' '}
                <a href="https://www.facebook.com/doctoroncallMY" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-fb.svg'}
                    src={'/images/socmed-fb.svg'}
                    alt={"fb-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://www.instagram.com/doctoroncallmy/" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-ig.svg'}
                    src={'/images/socmed-ig.svg'}
                    alt={"ig-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://twitter.com/doctoroncallmy" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-tw.svg'}
                    src={'/images/socmed-tw.svg'}
                    alt={"tw-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://www.youtube.com/c/DoctorOnCallMalaysia" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-yt.svg'}
                    src={'/images/socmed-yt.svg'}
                    alt={"yt-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>{' '}
                <a href="https://www.linkedin.com/company/doctoroncall.com.my" target="_blank">
                  <Image
                    blurDataURL={'/images/socmed-in.svg'}
                    src={'/images/socmed-in.svg'}
                    alt={"in-logo"}
                    width={27.5}
                    height={27.5}
                    loader={loaderProp}
                  /> 
                </a>
                {' '}
                </div>
              </div>
          </div>
          
        </div>
      </footer>
      <div className="copyright bg-white pt-05 pb-05 r-767 slide-disable t-05-ease sr">
        <div className="wrap-1 pad flex-box col2">
          <div className="mb-0 f-g1-s1">
            <p>
              <span>Copyright © {curYear}</span>
            </p>
          </div>
          <div>
            <div className="mb-0 f-g1-s1 ta-r">
              <p>
                <a href={basepath(`${locale==='en'?'':"/"+locale}/privacy-policy`)}>{t('privacyPolicy')} | </a>
                <a href={basepath(`${locale==='en'?'':"/"+locale}/term-of-use`)}>{t('TermUse')}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
