import searchPriority from 'json/searchPriorities.json'

const findKeyForPath = (path, searchPriority) => {
  if (path === '/') {
    return ["pharmacy"]
  }

  const urlSegment =  path.substring(0, path.indexOf('/', 1) !== -1 ? path.indexOf('/', 1) : path.length);

  const exactMatch = Object.keys(searchPriority).find(key => searchPriority[key].some(path => path === urlSegment))

  return exactMatch ? [exactMatch] : ["pharmacy"]
};

const searchPriorityGenerator = ({ route }) => {

  if (!route || typeof route !== 'string') {
    console.error('Invalid route provided.')
    return ["pharmacy"]
  }

  if (!searchPriority || typeof searchPriority !== 'object') {
    console.error('Invalid search priority object.')
    return ["pharmacy"]
  }

  return findKeyForPath(route, searchPriority)
};

module.exports = { searchPriorityGenerator }
