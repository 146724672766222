const path = require('path');


module.exports = {
  i18n: {
    locales: ['en','id-ID','en-SG'],
    defaultLocale: 'en',
  },
  localePath: path.resolve('./public/locales'),
}
