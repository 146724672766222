import { useState, useEffect } from 'react'
import { load } from 'recaptcha-v3-enterprise'
import Api from '#api/Auth'
import CountryCodes from './CountryCodes'
import { toast } from 'react-toastify'
import basepath from '#components/basepath'
import { useTranslation } from 'next-i18next'
import PhoneNumberInput from '#components/phoneNumberInput'
import { useSignInOverlay, useSignUpOverlay } from '../global-storage/overlays'
import { useAuthPhone, useIsLoggedIn } from '../global-storage/auth'
import GoogleSignIn from '#components/GoogleSignIn'
import getCountryandIP from '#components/getCountryIP'

export default function SignInOverlay({isMobile}) {
  // global state ======================
  const { state: signInOverlayState, closeOverlay: closeSignInOverlay } = useSignInOverlay(
    ({ state, closeOverlay }) => ({ state, closeOverlay })
  )
  const { setState: setSignUpOverlayState } = useSignUpOverlay(({ setState }) => ({ setState }))
  const { setIsLoggedIn } = useIsLoggedIn(({ setIsLoggedIn }) => ({ setIsLoggedIn }))
  const { setAuthPhone } = useAuthPhone(({ setAuthPhone }) => ({ setAuthPhone }))
  // ===================================

  const { t } = useTranslation('auth')
  const [userInput, setUserInput] = useState({
    countryCode: '60',
    phoneNumber: null,
    otp: null,
    emailOtp: null,
    sessionId: null,
    email: null,
    password: null,
    repeatPassword: null
  })
  const [defaultMobile, setDefaultMobile] = useState({
    // set country default here
    countryCode: '60',
    phoneNumber: null
  })

  const [step, setStep] = useState('step1')
  const [timer, setTimer] = useState() //OTP timer
  const [emailTimer, setEmailTimer] = useState(0) //OTP timer
  const [OTPBox, setOTPBox] = useState() //OTP timer
  const [hiddenMobile, setHiddenMobile] = useState() //Display hidden mobile on OTP step
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [passwordAlert, setPasswordAlert] = useState('')
  const [showSignUpMessage, setShowSignUpMessage] = useState(true)
  const [otpMethod, setOtpMethod] = useState('WhatsApp');

  //get recaptcha token
  const getRecaptchaToken = async () => {
    const recaptcha = await load(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY)
    const token = await recaptcha.execute('submit')
    return token
  }

  //OTP Timer
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevCountdown) => prevCountdown - 1)
    }, 1000)
    // Stop the timer when it reaches 0
    if (timer === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  useEffect(async() => {
    let test = await getCountryandIP()
    setDefaultMobile(
      {
        countryCode: test.country_calling_code.substring(1),
        phoneNumber: null
      }
    )
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setEmailTimer((prevCountdown) => prevCountdown - 1)
    }, 1000)
    // Stop the timer when it reaches 0
    if (emailTimer === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [emailTimer])

  //Only show last four digits of mobile number
  useEffect(() => {
    const countryCode = userInput.countryCode
    const mobile = userInput.phoneNumber
    var lastFour = mobile?.substr(mobile.length - 4)
    setHiddenMobile(`${countryCode}XXXXX${lastFour}`)
  }, [step])

  //reset overlay to step1
  useEffect(() => {
    handleStepChange('step1')
  }, [signInOverlayState])

  //update user input state
  const handleUserInput = (key, value) => {
    setUserInput({
      ...userInput,
      [key]: value
    })
  }
  //handle next step
  const handleStepChange = async (nextStep) => {
    if (nextStep == 'step3') {
      let app_env = process.env.NEXT_PUBLIC_ENV
      try {
        //validate
        let isValid = true
        //validate user input
        const fields = { countryCode: t('countryCode'), phoneNumber: t('phoneNumber') }
        for (const field in fields) {
          if (!userInput[field]) {
            toast.warning(t('pleaseEnter') + fields[field], {
              position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
            })
            isValid = false
          }
        }
        if (!isValid) {
          return
        }
        //send OTP to whatsapp as first option
        app_env ==='local' || app_env ==='dev' || app_env ==='uat'? sendOtp('SMS') : sendOtp('WHATSAPP')
      } catch (error) {
        console.log(error)
        toast.warning(t('errorOccured'), {
          position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
        })
        setShowSignUpMessage(true)
        return
      }
    } else if (nextStep == 'step4') {
      console.log(emailTimer)
    }
    setStep(nextStep)
  }

  const validatePassword = () => {
    let isValid = true
    //check ic length
    if (userInput.password?.length < 8) {
      isValid = false
      setPasswordAlert('At least 8 characters.')
    }
    //check for capital letter
    const capitalLetterRegex = /[A-Z]/
    const containsCapitalLetter = capitalLetterRegex.test(userInput.password)
    if (!containsCapitalLetter) {
      isValid = false
      setPasswordAlert('Must contain capital letter.')
    }
    // check for number
    if (!/\d/.test(userInput.password)) {
      isValid = false
      setPasswordAlert('Must contain a number.')
    }
    //check for special character
    const specialCharRegex = /[!@#$%^&*()\-_=+[\]{}|;:'",.<>/?\\]/
    const containsSpecialChar = specialCharRegex.test(userInput.password)
    if (!containsSpecialChar) {
      isValid = false
      setPasswordAlert('Must contain special character.')
    }
    if (!isValid) {
      clearPassword()
    }
    return isValid
  }
  //update user input state
  const clearPassword = () => {
    setUserInput({
      ...userInput,
      password: '',
      repeatPassword: ''
    })
  }
  // Send OTP function
  const sendOtp = async (type) => {
    setOtpMethod(type==="WHATSAPP"?"WhatsApp":type)
    setTimer(60)
    try {
      const token = await getRecaptchaToken()
      const res = await Api.phoneNumber(type, userInput.countryCode, userInput.phoneNumber, token)
      handleUserInput('sessionId', res.sessionId)
    } catch (error) {
      //if phone number is not found at doc auth direct the user to signup flow
      if (JSON.parse(error?.message)?.detail == 'User not found') {
        setAuthPhone({
          countryCode: userInput.countryCode,
          phoneNumber: userInput.phoneNumber
        })
        closeSignInOverlay()
        setSignUpOverlayState('active')
        return
      }
      console.log(error.message)
      toast.warning(error.message ? JSON.parse(error.message)?.detail ? JSON.parse(error.message)?.detail : t('errorOccuredOtp') : t('errorOccuredOtp'), {
        position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
      })
      setShowSignUpMessage(true)
      // handleStepChange('step2')
    }
  }
  const sendEmailOtp = async () => {
    try {
      if (userInput.email) {
        const token = await getRecaptchaToken()
        const res = await Api.emailOtp(userInput.email, token)
        handleUserInput('sessionId', res.sessionId)
        setEmailTimer(60)
        setOTPBox(true)
      } else {
        toast.warning(t('pleaseEnterEmail'), {
          position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
        })
      }
    } catch (error) {
      console.log(error.message)
      toast.warning(error.message ? JSON.parse(error.message)?.detail : t('errorOccured'), {
        position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
      })
      handleStepChange('step1')
    }
  }

  const mobileSignIn = async () => {
    try {
      //call otp-login API

      const code = userInput.otp.replace(/\s/g, '')
      if (code?.length < 1) {
        toast.warning(t('pleaseEnterOtp'), {
          position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
        })
        return
      }
      const sessionId = userInput.sessionId
      const recaptchaToken = await getRecaptchaToken()
      const res = await Api.otpLogin(code, sessionId, recaptchaToken)
      //store in local storage
      const user = await Api.verifyToken(res.json.token, res.token) //note: first token is doc auth token, second token in b2c platform token
      localStorage.setItem('userDetails', JSON.stringify(user))
      
      //update states
      closeSignInOverlay()
      setIsLoggedIn(true)
      //redirct to homepage if on register page
      if (window.location.pathname.includes('register')) {
        window.location.href = basepath('/')
      }else{
        window.location.reload();
      }
    } catch (error) {
      if (JSON.parse(error?.message)?.detail == 'User not found') {
        registerExistingUser('mobile')
        return
      }
      setShowSignUpMessage(true)
      console.log(error.message)
      toast.warning(error.message ? JSON.parse(error.message)?.detail : t('errorOccured'), {
        position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
  const signIn = async () => {
    try {
      //call login API
      const email = userInput.email
      const password = userInput.password
      const recaptchaToken = await getRecaptchaToken()
      const res = await Api.login(email, password, recaptchaToken)
      //store in local storage
      const user = await Api.verifyToken(res.json.token, res.token) //note: first token is doc auth token, second token in b2c platform token
      localStorage.setItem('userDetails', JSON.stringify(user))
      //update states
      closeSignInOverlay()
      setIsLoggedIn(true)
      //redirct to homepage if on register page
      if (window.location.pathname.includes('register')) {
        window.location.href = basepath('/')
      }else{
        window.location.reload();
      }
    } catch (error) {
      if (JSON.parse(error?.message)?.detail == 'User not found') {
        registerExistingUser('email')
        return
      }
      setShowSignUpMessage(true)
      console.log(error.message)
      toast.warning(JSON.parse(error.message)?.detail || t('errorOccured'), {
        position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        onClose: () => {
          handleStepChange('step2')
        }
      })
    }
  }

  const resetPassword = async () => {
    const isValid = validatePassword()
    if (!isValid) {
      return
    }
    if (userInput.password === userInput.repeatPassword) {
      try {
        const token = await getRecaptchaToken()
        await Api.resetPassword(userInput.sessionId, userInput.emailOtp, userInput.password, token)
        handleStepChange('step6')
      } catch (error) {
        console.log(error)
        toast.warning(error.message ? JSON.parse(error.message)?.detail : t('errorOccured'), {
          position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
        })
      }
    } else {
      toast.warning(t('passwordMismatch'), {
        position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
  const registerExistingUser = async (method) => {
    try {
      //define payload
      let payload
      if (method == 'email') {
        payload = {
          email: userInput.email,
          password: userInput.password,
          recaptchaToken: await getRecaptchaToken()
        }
      } else {
        //Check OTP

        if (userInput.otp.length < 1) {
          toast.warning(t('pleaseEnterOtp'), {
            position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
          })
          return
        }
        payload = {
          code: userInput.otp,
          sessionId: userInput.sessionId,
          recaptchaToken: await getRecaptchaToken()
        }
      }
      //call api
      let res = await Api.registerExistingUser(payload)
      //store in local storage
      const user = await Api.verifyToken(res.json.token, res.token) //note: first token is doc auth token, second token in b2c platform token
      localStorage.setItem('userDetails', JSON.stringify(user))
      //update states
      setIsLoggedIn(true)
      closeSignInOverlay()
    } catch (error) {
      console.log(error)
      toast.warning(t('errorOccured2'), {
        position: isMobile && isMobile.windowSize<767? toast.POSITION.TOP_RIGHT :toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
  const handleKeyPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback()
    }
  }
  return (
    <>
      <div
        id="overlay-content-wrapper"
        className={`signin-form overlay flex-box ai-c jc-c pos-fix ${step} ${signInOverlayState}`}
      >
        {/* Step0: email sign in */}
        <div className="f-g1-s0">
          <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
            <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
              <div>
                <img
                  src={basepath('/images/ico-account.svg')}
                  alt="account"
                  className="account-img"
                />
              </div>
              <div className="overlay-title f-g1-s0">
                {t('signIn')} - {t('email')}
              </div>
            </div>
            <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1">
              <div>
                <input
                  id="email"
                  type="email"
                  className="fullwidth"
                  placeholder={t('email')}
                  onChange={(event) => handleUserInput('email', event.target.value)}
                />
              </div>
              <div className="pos-rel">
                <input
                  id="password"
                  type={passwordVisible ? 'text' : 'password'}
                  className="fullwidth"
                  placeholder={t('password')}
                  onChange={(event) => handleUserInput('password', event.target.value)}
                  onKeyDown={(e) => handleKeyPress(e, signIn)}
                />
                <a
                  id="password-reveal"
                  className={`eye ${passwordVisible ? 'reveal' : ''} pos-abs`}
                  onClick={() => {
                    setPasswordVisible(!passwordVisible)
                  }}
                ></a>
              </div>
              <div className="f-g1-s0 pl-1 pr-1">
                <a
                  className="fs-08"
                  id="fp"
                  onClick={() => {
                    handleStepChange('step4')
                  }}
                >
                  {t('forgotPass')}
                </a>
              </div>
              <div>
                {showSignUpMessage && (
                  <div className="fs-08 mt-1">
                    {t('noAccount')}
                    <a
                      onClick={() => {
                        closeSignInOverlay(), setSignUpOverlayState('active')
                      }}
                      className="fs-1"
                    >
                      {t('here')}
                    </a>
                  </div>
                )}
                <div className="flex-box gap-05 mt-1">
                  <button
                    id="back"
                    className="back-button pl-1 pr-1"
                    onClick={() => {
                      handleStepChange('step1')
                    }}
                  >
                    <img src={basepath('/images/prev-white.svg')} className="back-btn-arrow" alt />
                  </button>
                  <button
                    className="signin button-red button-email fullwidth"
                    type="button"
                    onClick={() => signIn()}
                  >
                    {t('signInEmail')}
                  </button>
                </div>
              </div>
              {/* <div><button className="signin button-google fullwidth" type="button">Sign In with Google</button></div> */}
            </div>
          </div>
        </div>
        {/* step 1: main screen (user can click mobile or email login) */}
        <div className="f-g1-s0">
          <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
            <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
              <div>
                <img
                  src={basepath('/images/ico-account.svg')}
                  alt="account"
                  className="account-img"
                />
              </div>
              <div className="overlay-title f-g1-s0">{t('signIn')}</div>
              <div className="fs-08">
                {t('noAccount')}{' '}
                <a
                  className="fs-1"
                  onClick={() => {
                    closeSignInOverlay(), setSignUpOverlayState('active')
                  }}
                >
                  {t('here')}
                </a>
              </div>
              <div>
                <img
                  id="close-overlay"
                  src={basepath('/images/ico-close.png')}
                  alt="close"
                  onClick={() => {
                    closeSignInOverlay()
                  }}
                />
              </div>
            </div>
            <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1">
              <div className="pt-2 pb-2 f-g1-s0">
                <img
                  className="fullwidth centralized"
                  style={{ maxWidth: '8em' }}
                  src={basepath('/images/doc-logo.png')}
                  alt="logo"
                />
              </div>
              <div>
                <button
                  id="simobile"
                  className="button-red button-phone fullwidth"
                  type="button"
                  onClick={() => {
                    handleStepChange('step2')
                  }}
                >
                  {t('signInMobile')}
                </button>
              </div>
              <div className="flex-box">
                <hr className="f-g1-s0" />
                <strong className="pl-1 pr-1">OR</strong>
                <hr className="f-g1-s0" />
              </div>
              <div>
                <button
                  id="siemail"
                  className="button-red button-email fullwidth"
                  type="button"
                  onClick={() => {
                    handleStepChange('step0')
                  }}
                >
                  {t('signInEmail')}
                </button>
              </div>
              <div>
                <GoogleSignIn></GoogleSignIn>
              </div>
              <div className='pointer-hover' onClick={() => {closeSignInOverlay(), setSignUpOverlayState('active')}}>
                <img src={basepath('/images/banners/MP_Signup_Banner.png')} className='fullwidth br-05'></img>
              </div>
              {/* <div><button className="signin button-google fullwidth" type="button">Sign In with Google</button></div> */}
            </div>
          </div>
        </div>
        {/* step 2: Mobile sign in */}
        <div className="f-g1-s0">
          <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
            <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
              <div>
                <img
                  src={basepath('/images/ico-account.svg')}
                  alt="account"
                  className="account-img"
                />
              </div>
              <div className="overlay-title f-g1-s0">{t('signInMobile')}</div>
            </div>
            <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
              <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1">
                <div className="flex-box gap-05">
                  <div className="phone-wrapper fb-48 f-g1-s1">
                    <PhoneNumberInput
                      mobile={userInput}
                      setMobile={setUserInput}
                      defaultMobile={defaultMobile}
                      disabled={false}
                    />
                    {/* <div className="f-g1-s1"><input id="phone" type="phone" className="fullwidth" placeholder="Mobile Number" disabled={!editAccountInfo} onChange={(event) => handleAccountInfoChange('phoneNumber', event.target.value)}/></div> */}
                  </div>
                  {/* <div className="dropdown-select">
                        <CountryCodes handleUserInput={handleUserInput}></CountryCodes>
                    </div>
                    <div className="f-g1-s1"><input id="phone" type="phone" className="fullwidth" placeholder={t('mobileNo')} onChange={(event) => handleUserInput('phoneNumber', event.target.value)}/></div> */}
                </div>
                <div className="f-g1-s1 pl-1 pr-1">
                  <p>{t('mobileLoginMsg')}</p>
                </div>
                <div>
                  {showSignUpMessage && (
                    <div className="fs-08 mt-1">
                      {t('noAccount')}
                      <a
                        onClick={() => {
                          closeSignInOverlay(), setSignUpOverlayState('active')
                        }}
                        className="fs-1"
                      >
                        {t('here')}
                      </a>
                    </div>
                  )}

                  <div className="flex-box gap-05 mt-1">
                    <button
                      id="back"
                      className="back-button pl-1 pr-1"
                      onClick={() => {
                        handleStepChange('step1')
                      }}
                    >
                      <img
                        src={basepath('/images/prev-white.svg')}
                        className="back-btn-arrow"
                        alt
                      />
                    </button>
                    <button
                      id="sotp"
                      className="f-g1-s1 button-red fullwidth"
                      type="button"
                      onClick={() => {
                        handleStepChange('step3')
                      }}
                    >
                      {t('sendOtp')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-g1-s0">
          <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
            <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
              <div>
                <img
                  src={basepath('/images/ico-account.svg')}
                  alt="account"
                  className="account-img"
                />
              </div>
              <div className="overlay-title f-g1-s0">{t('signIn')} - OTP</div>
            </div>
            <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
              <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1">
                <div className="flex-box gap-05">
                  <input
                    id="otp"
                    type="text"
                    className="fullwidth"
                    placeholder="Enter OTP"
                    onChange={(event) => handleUserInput('otp', event.target.value)}
                  />
                </div>
                <div className="f-g1-s1 pl-1 pr-1">
                  <div style={{ display: timer == 0 ? 'none' : 'block' }}>
                    <p>
                      {t('otpMsg')} <strong className="fs-1">{hiddenMobile} </strong>
                      via {otpMethod}. {t('otpMsg2')}
                    </p>
                    {timer ? (
                      <p>
                        {t('resentOTPIn')} <strong className="fs-1">{timer}</strong>
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <p style={{ display: timer == 0 ? 'block' : 'none' }}>
                    {' '}
                    {t('notGettingOTP')}{' '}
                    <a
                      className="fs-1 fw-800"
                      onClick={() => {
                        sendOtp('WHATSAPP')
                      }}
                    >
                      WhatsApp
                    </a>{' '}
                    ,{' '}
                    <a
                      className="fs-1 fw-800"
                      onClick={() => {
                        sendOtp('SMS')
                      }}
                    >
                      SMS
                    </a>{' '}
                    or{' '}
                    <a
                      className="fs-1 fw-800"
                      onClick={() => {
                        sendOtp('CALL')
                      }}
                    >
                      Call
                    </a>
                    .
                  </p>
                </div>
                <div>
                  {showSignUpMessage && (
                    <div className="fs-08 mt-1">
                      {t('noAccount')}
                      <a
                        onClick={() => {
                          closeSignInOverlay(), setSignUpOverlayState('active')
                        }}
                        className="fs-1"
                      >
                        {t('here')}
                      </a>
                    </div>
                  )}
                  <div className="flex-box gap-05 mt-1">
                    <button
                      id="back"
                      className="back-button pl-1 pr-1"
                      disabled={timer > 0}
                      onClick={() => {
                        handleStepChange('step2')
                      }}
                    >
                      <img
                        src={basepath('/images/prev-white.svg')}
                        className="back-btn-arrow"
                        alt
                      />
                    </button>
                    <button
                      id="step2"
                      className="signin f-g1-s1 button-red fullwidth"
                      type="button"
                      onClick={() => {
                        mobileSignIn()
                      }}
                    >
                      {t('signIn')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-g1-s0">
          <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
            <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
              <div>
                <img
                  src={basepath('/images/ico-account.svg')}
                  alt="account"
                  className="account-img"
                />
              </div>
              <div className="overlay-title f-g1-s0">{t('forgotPass')}?</div>
            </div>
            <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
              <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1">
                <div className="flex-box gap-05 fd-c">
                  <div>
                    <input
                      id="email"
                      type="email"
                      className="fullwidth"
                      placeholder={t('registeredEmail')}
                      onChange={(event) => {
                        handleUserInput('email', event.target.value)
                      }}
                    />
                  </div>
                  {OTPBox == true ? (
                    <div>
                      <input
                        id="otp"
                        type="text"
                        className="fullwidth"
                        placeholder={t('pleaseEnterOtp')}
                        onChange={(event) => {
                          handleUserInput('emailOtp', event.target.value)
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="f-g1-s1 pl-1 pr-1">
                  <p>{t('emailOtpMsg')}</p>

                  {/* WHEN OTP IS INITIALIZED */}
                  <div style={{ display: emailTimer > 0 ? 'block' : 'none' }}>
                    <p>
                      One-Time Password (OTP) is being sent to{' '}
                      <strong className="fs-1">{userInput.email}</strong> via Email. Please key in
                      the OTP above.
                    </p>
                    <p>
                      {t('resentOTPIn')} <strong className="fs-1">{emailTimer}</strong>
                    </p>
                  </div>
                </div>
                <div className="flex-box gap-05">
                  <button
                    id="back"
                    className="back-button pl-1 pr-1"
                    disabled={emailTimer > 0}
                    onClick={() => {
                      handleStepChange('step0')
                    }}
                  >
                    <img src={basepath('/images/prev-white.svg')} className="back-btn-arrow" alt />
                  </button>
                  <button
                    id="sotp"
                    className="f-g1-s1 button-red fullwidth"
                    type="button"
                    style={{ display: emailTimer > 0 ? 'none' : 'block' }}
                    onClick={() => {
                      sendEmailOtp()
                    }}
                  >
                    {t('sendOtp')}
                  </button>
                  <button
                    id="step3"
                    className="f-g1-s1 button-red fullwidth"
                    style={{ display: emailTimer > 0 ? 'block' : 'none' }}
                    onClick={() => {
                      handleStepChange('step5')
                    }}
                    type="button"
                  >
                    {t('next')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-g1-s0">
          <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
            <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
              <div>
                <img
                  src={basepath('/images/ico-account.svg')}
                  alt="account"
                  className="account-img"
                />
              </div>
              <div className="overlay-title f-g1-s0">{t('forgotPass')}</div>
            </div>
            <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
              <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1">
                <div className="flex-box gap-05 fd-c">
                  <div>
                    <input
                      id="password"
                      type="password"
                      className={`fullwidth ${passwordAlert ? 'input-alert' : ''}`}
                      value={userInput.password}
                      placeholder={passwordAlert ? passwordAlert : 'Enter new password'}
                      onChange={(event) => handleUserInput('password', event.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      id="password-repeat"
                      type="password"
                      className="fullwidth"
                      value={userInput.repeatPassword}
                      placeholder="Verify new password"
                      onChange={(event) => handleUserInput('repeatPassword', event.target.value)}
                    />
                  </div>
                </div>
                <div className="f-g1-s1 pl-1 pr-1">
                  <p>{t('CreateNewPassword')}</p>
                </div>
                <div className="flex-box gap-05">
                  <button
                    id="back"
                    className="back-button pl-1 pr-1"
                    onClick={() => {
                      handleStepChange('step4')
                    }}
                  >
                    <img src={basepath('/images/prev-white.svg')} className="back-btn-arrow" alt />
                  </button>
                  <button
                    id="reset-p"
                    className="f-g1-s1 button-red fullwidth"
                    type="button"
                    onClick={() => {
                      resetPassword()
                    }}
                  >
                    {t('resetPassword')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="f-g1-s0">
          <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
            <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
              <div>
                <img
                  src={basepath('/images/ico-account.svg')}
                  alt="account"
                  className="account-img"
                />
              </div>
              <div className="overlay-title f-g1-s0">Password reset successfully!</div>
            </div>
            <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
              <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1">
                <div className="f-g1-s1 pl-1 pr-1">
                  <p>
                    Good news! Your password has been reset, enhancing your account's security.
                    Remember, your new password is your secret.
                  </p>
                </div>
                <div className="flex-box gap-05">
                  <button
                    id="sitm"
                    className="f-g1-s1 button-red fullwidth"
                    type="button"
                    onClick={() => {
                      handleStepChange('step0')
                    }}
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="overlay"
        className={`overlay flex-box ai-c jc-c pos-fix ${signInOverlayState}`}
        onClick={() => {
          closeSignInOverlay()
        }}
      ></div>
    </>
  )
}