import { useQuery } from 'react-query'
import Api from '../api/Market'

// this gets the categories initially from the server, then from localStorage
// then from the server again after 1 hour, and so on
// this is to avoid having to fetch the categories on every page load
export const useProductCategories = () => {
  const isServer = typeof window === 'undefined';

  // Synchronously check for localStorage data
  let initialData;
  if (!isServer) {
    try {
      const localData = localStorage.getItem('productCategories');      
      if (localData) {
        initialData = JSON.parse(localData);
      }
    } catch (error) {
      console.log('Failed to read from localStorage:', error);
    }
  }

  const query = useQuery('productCategories', () => Api.getProductCategories(), {
    initialData,
    staleTime: 1000 * 60 * 60, // Data will become stale after 1 hour
    refetchInterval: 1000 * 60 * 60, // Refetch every 1 hour
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (!isServer) {
        try {
          localStorage.setItem('productCategories', JSON.stringify(data));          
        } catch (error) {
          console.errlogor('Failed to write to localStorage:', error);
        }
      }
    },
  });

  return {...query, data: query.data || []};
};

export const useFeaturedProductCategories = () => {
    const isServer = typeof window === 'undefined';
    
    // Synchronously check for localStorage data
    let initialData;
    if (!isServer) {
      const localData = localStorage.getItem('featuredProductCategories');
      if (localData) {
        initialData = JSON.parse(localData);
      }
    }
  
    const query = useQuery('featuredProductCategories', () => Api.getFeaturedProductCategories(), {
      initialData,
      staleTime: 1000 * 60 * 60, // Data will become stale after 1 hour
      refetchInterval: 1000 * 60 * 60, // Refetch every 1 hour
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!isServer) {
          localStorage.setItem('featuredProductCategories', JSON.stringify(data));
        }
      },
    });
  
    return {...query, data: query.data || []};
  };

export const usePackageCategories = () => {
  const isServer = typeof window === 'undefined';

  // Synchronously check for localStorage data
  let initialData;
  if (!isServer) {
    const localData = localStorage.getItem('packageCategories');
    if (localData) {
      initialData = JSON.parse(localData);
    }
  }

  const query = useQuery('packageCategories', () => Api.getPackageCategories({location:null}), {
    initialData,
    staleTime: 1000 * 60 * 60, // Data will become stale after 1 hour
    refetchInterval: 1000 * 60 * 60, // Refetch every 1 hour
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (!isServer) {
        localStorage.setItem('packageCategories', JSON.stringify(data));
      }
    },
  });

  return {...query, data: query.data || []};
};