const strToBool = (str) => {
  return str === 'true'
}
const isValidJson=(str)=> {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

module.exports = { strToBool, isValidJson }
