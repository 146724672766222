export const fetchLocationData = async () => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();

    if (data.error) {
      console.error('ipapi error', data.reason)
      return null
    }
    return data;
  } catch (error) {
    console.error('Error fetching location', error);
    return null;
  }
};
