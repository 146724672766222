import React, { useState, useEffect } from "react";
import { useSession, signIn, signOut } from "next-auth/react"
import { useTranslation } from 'next-i18next'

export default function GoogleSignIn() {
  const { t } = useTranslation('auth')
  return (
    <>
    <button className="signin button-google fullwidth" type="submit" onClick={(e) => {
        e.preventDefault();
        signIn("google", { callbackUrl: window.location.href });
      }}
    >{t('signInGoogle')}</button>
    </>
  );
}