import React, { Component } from 'react'
import basepath from '#components/basepath'

const logo = '/img/logo.gif'

export default class Loader extends Component {
  constructor() {
    super()
    this.state = {}
  }

  render() {
    return (
      <>
        <div
          className="fullheight fullwidth flex-box ai-c jc-c pos-fix"
          style={{
            zIndex: 1000,
            top: 0,
            backgroundColor: 'rgba(255,255,255,0.8)'
          }}
        >
          <img alt="logo" src={basepath(logo)} width="70" height="70" />
          {/* <h5 className="text-center font-weight-bold text-black-50 mt-3">Loading...</h5> */}
        </div>
      </>
    )
  }
}
