import 'react-tabs/style/react-tabs.css'
import 'react-toastify/dist/ReactToastify.css'
import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from '#root/next-i18next.config.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import MainLayout from '../layouts/main'
import {SessionProvider} from 'next-auth/react'
import { GoogleOAuthProvider } from '@react-oauth/google';

const queryClient = new QueryClient()
function MyApp({ Component, pageProps,session }) {

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <SessionProvider session={session}>
          <MainLayout Component={Component} pageProps={pageProps} />
        </SessionProvider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  )
}


export default appWithTranslation(MyApp, nextI18NextConfig)
