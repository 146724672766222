import React, { useState, useEffect } from "react";
import basepath from '#components/basepath';
import ReactCountryFlag from "react-country-flag"
import { useTranslation } from 'next-i18next'


export default function CountryPopup({ locale,availableCountries }) {
  const [overlayState, setOverlayState] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(locale);
  const [country, setCountry] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const { t, ready } = useTranslation('common')
  const current_country=(availableCountries.filter(country=>country.code==locale))[0]


  useEffect(async () => {
    // if the user hasn'e seen the popup or their locale is different from their selected country
    if(!localStorage.getItem('selectedCountry')|| locale!==localStorage.getItem('selectedCountry').toLocaleLowerCase()){
      let ipTrackRes=(await getGeoInfo())
      // Singapre and Indoniesa are displied as their name, others are displayed as international
      setCountry(ipTrackRes?.country_name=='Singapore' || ipTrackRes?.country_name=='Indonesia'? ipTrackRes?.country_name:'International')
      setCountryCode(ipTrackRes?.country==='SG' || ipTrackRes?.country==='ID'?ipTrackRes?.country: 'en')
    }
  }, []);

  const getGeoInfo = async () => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const res = await response.json();

      localStorage.setItem('IPCountry', res.country);
      const storedCountry = localStorage.getItem('selectedCountry');

      if (storedCountry && storedCountry===locale) {
        setSelectedCountry(storedCountry);
        setOverlayState(false);
      } else if (res?.country?.toLowerCase() == locale) {
        setOverlayState(false);
      }else{
        setOverlayState(true);
      }
      return res
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    localStorage.setItem('selectedCountry', newCountry);
    setOverlayState(false);
  
    // Get the current pathname
    const currentPathname = window.location.pathname;
  
    // Extract the existing country code from the pathname
    const matches = currentPathname.match(/^\/([a-z]{2})\//);
  
    if (matches) {
      const currentCountryCode = matches[1];
  
      // Define country-specific path segments
      const pathSegments = {
        id: {
          apotek: '/apotek',
          pharmacy: '/apotek',
        },
        sg: {
          apotek: '/apotek',
          pharmacy: '/pharmacy',
        },
        en: {
          apotek: '/apotek',
          pharmacy: '/pharmacy',
        },
        // Add more country-specific segments as needed
      };
  
      // Determine the current country-specific segment
      const currentCountrySegment = pathSegments[currentCountryCode] ||pathSegments['en'];
  
      // Determine the new country-specific segment
      const newCountrySegment = pathSegments[newCountry];
  
      // Replace the current country code with the selected country code
      let newPathnameWithoutCountrySegment = currentPathname.replace(
        `/${currentCountryCode}`,
        `${newCountry=='en'?'':'/'+newCountry}`
      );
  
      // Replace the current country-specific path segment with the new one
      if (currentCountrySegment.apotek && newPathnameWithoutCountrySegment.includes(currentCountrySegment.apotek)) {
        newPathnameWithoutCountrySegment = newPathnameWithoutCountrySegment.replace(
          currentCountrySegment.apotek,
          newCountrySegment.pharmacy
        );
      } else if (currentCountrySegment.pharmacy && newPathnameWithoutCountrySegment.includes(currentCountrySegment.pharmacy)) {
        newPathnameWithoutCountrySegment = newPathnameWithoutCountrySegment.replace(
          currentCountrySegment.pharmacy,
          newCountrySegment.apotek
        );
      }
  
      // console.log(newPathnameWithoutCountrySegment);
      // Update the URL by navigating to it
      window.location.replace(newPathnameWithoutCountrySegment);
    }else{

      // console.log((newCountry=='en'?"":"/"+newCountry)+currentPathname);
      window.location.replace((newCountry=='en'?"":"/"+newCountry)+currentPathname);

    }
  };
  
  const handleCountryClick = (newCountry,skipRedirect) => {
    setSelectedCountry(newCountry);
    setOverlayState(false);
    // Save the selected country in local storage
    localStorage.setItem('selectedCountry', newCountry);
    if(!skipRedirect){
      window.location.href= (newCountry==='en'? '/' : '/'+newCountry.toLowerCase())
    }
  };

  const countryFlag =(countryCode)=>(
    countryCode=='en'?
    <img width='1.5em' style={{width: '1.5em',marginRight:'1em'}}src={basepath('/images/globe.svg')}/>
    :
    <ReactCountryFlag 
      countryCode={countryCode}
      style={{
          fontSize: '1.5em',
          marginRight:'1em'
      }}
      svg
    />
  )

  return (
    <>
      {overlayState &&current_country.name!==country &&(
        <>
          <div
            id="overlay-content-wrapper"
            style={{ width: "100%" }}
            className={`overlay flex-box col7 ai-c jc-c pos-fix ${overlayState ? 'active' : 'inactive'}`}
          >
            <div className="f-g1-s0">
              <div className="overlay-content bg-white br-05 centralized flex-box fd-c">
                <div className="overlay-header flex-box pad-1 ai-b bg-blue-1 br-05 gap-1">
                  <div>
                    <img src={basepath('/images/icon-attention.svg')} alt="attention" className="account-img" />
                  </div>
                  <div className="overlay-title f-g1-s0">Select Country</div>
                </div>
                <div className="overlay-body sigin-signup-register pad-1 flex-box fd-c gap-1" style={{textAlign:'center'}}>
                  <p className="fs-1 mb-0"><strong>{t('shoppingFrom')} {country} site</strong> <br/><br/></p>
                  <div className="flex-box jc-c gap-05">
                    
                    { countryFlag(current_country.code)} {/* Cuurent country  based on locale*/}
                    <img width='1.5em' style={{width: '1.5em',marginRight:'1em'}}src={basepath('/images/arrow.svg')}/>
                    { countryFlag(countryCode)} {/* Country based on IP */}
                  </div>
                  <div>
                    <p className="fs-1 mt-1 mb-1">{t('viewingOur')} <strong>{current_country.name} site</strong>.</p>
                    <p className="fs-1 mb-0">{t('toServeYouBetter')} <strong> DoctorOnCall {country} site </strong> ?<br/><br/></p>
                  </div>
                  {/* Use anchor tags for selecting countries */}
                  <button
                      key={countryCode}
                      onClick={() => handleCountryClick(countryCode)}
                      className='button-red fullwidth mt-1'
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                     {t('continueTo')} DoctorOnCall {country} site
                  </button>
                  <button className='button-red i fullwidth' onClick={()=>{setOverlayState(false);handleCountryClick(current_country.code,true)}}>
                    {t('dontRedirectMe')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            id="overlay"
            className={`overlay flex-box ai-c jc-c pos-fix ${overlayState ? 'active' : 'inactive'}`}
          ></div>
        </>
      )}
    </>
  );
}
